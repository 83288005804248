<template lang="pug">
#day-two-warning(v-esc-close="cancel")
  .icon-container
    icon#clock-icon(:data="require('@icon/clock.svg')")
  .modal-header
    h2 Come Back Later! <br> You Haven't Waited Long Enough
  .modal-text
    p Come back tomorrow morning <strong>between 6AM and 10AM</strong> to collect your 2nd sample.
  .modal-footer
    div
      button.button.action-btn(@click="close")
        span I'll Come Back Later
      p.continue(@click="gotoNext") Proceed to Day 2 Anyway (Sample may be invalidated)
</template>

<script>
export default {
  props: {
    onAction: { type: Function, required: false },
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    gotoNext,
    cancel,
    close,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function cancel() {
  this.cancel()
}

function close() {
  this.$modal.close('DayTwoWarning')
}

function gotoNext() {
  this.onAction()
  this.$modal.closeAll()
}
</script>
